import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "visibility",
        label: "View details",
        to: { name: 'base.chats.settings.instagram.detailed' }
      }, null, 8, ["to"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "qr_code_2",
        label: "QR code & chat link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openInstagramQrCodeModal(_ctx.title, _ctx.chatLink)))
      }),
      (_ctx.status.name === 'Connection failed')
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 0,
            icon: "refresh",
            label: "Retry"
          }))
        : _createCommentVNode("", true),
      (_ctx.status.name === 'Permissions issue')
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 1,
            icon: "build",
            label: "Fix permissions"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_dropdown_item, {
        icon: "link_off",
        label: "Unlink",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openInstagramUnlinkModal(_ctx.title)))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}