import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65f3b2a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_chat_instagram_empty = _resolveComponent("chat-instagram-empty")!
  const _component_chat_instagram_filter = _resolveComponent("chat-instagram-filter")!
  const _component_chat_instagram_table = _resolveComponent("chat-instagram-table")!
  const _component_white_block = _resolveComponent("white-block")!
  const _component_chat_instagram_faq = _resolveComponent("chat-instagram-faq")!
  const _component_chats_settings_page = _resolveComponent("chats-settings-page")!

  return (_openBlock(), _createBlock(_component_chats_settings_page, {
    breadcrumbs: _ctx.breadcrumbs,
    class: "chat-instagram-messenger"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Instagram",
        class: "mb-4"
      }, {
        avatar: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/images/chats/instagram.svg'),
            height: "48",
            alt: ""
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_white_block, { class: "chat-instagram-messenger__content" }, {
        default: _withCtx(() => [
          (_ctx.isEmptyMode)
            ? (_openBlock(), _createBlock(_component_chat_instagram_empty, { key: 0 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_chat_instagram_filter, {
                  search: _ctx.search,
                  "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                  selected: _ctx.selected,
                  "search-placeholder": "Search accounts"
                }, null, 8, ["search", "selected"]),
                _createVNode(_component_chat_instagram_table, {
                  headers: _ctx.tableHeaders,
                  items: _ctx.tableItems,
                  "item-key": "id",
                  "items-name": "accounts",
                  search: _ctx.search
                }, null, 8, ["headers", "items", "search"])
              ], 64))
        ]),
        _: 1
      }),
      _createVNode(_component_chat_instagram_faq)
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}