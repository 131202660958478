
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilter,
  },
})
