import type { AccountListType } from '@/definitions/chats/settings/common/types'
import type { InstagramLineType } from '@/definitions/chats/settings/instagram/types'

export const instagramMock: InstagramLineType[] = [
  {
    id: 1,
    account: {
      title: 'Acme LLC (Australia)',
      avatarColor: 'purple',
      link: 'https://www.instagram.com/',
    },
    username: 'acme_llc_AU',
    chatLink: 'https://ig.me/acme_llc_AU',
    status: {
      name: 'Connected',
      color: 'green',
      outline: true,
    },
    dateConnected: '2024-05-07T17:58:27.118Z',
  },
  {
    id: 2,
    account: {
      title: 'Personal Storytelling Market',
      image: 'https://picsum.photos/id/222/200',
      avatarColor: 'red',
      link: 'https://www.instagram.com/',
    },
    username: 'personal_storytelling',
    chatLink: 'https://ig.me/personal_storytelling',
    status: {
      name: 'Connected',
      color: 'green',
      outline: true,
    },
    dateConnected: '2024-05-11T17:02:27.118Z',
  },
  {
    id: 3,
    account: {
      title: 'Welcome to Australia',
      avatarColor: 'blue',
      link: 'https://www.instagram.com/',
    },
    username: 'welcome_to_AU',
    chatLink: 'https://ig.me/welcome_to_AU',
    status: {
      name: 'Connection failed',
      color: 'red',
      outline: true,
    },
    dateConnected: '2024-06-08T11:11:27.118Z',
  },
  {
    id: 4,
    account: {
      title: 'Australia sim-racing',
      image: 'https://picsum.photos/id/99/200',
      avatarColor: 'mint',
      link: 'https://www.instagram.com/',
    },
    username: 'AU_sim_racing',
    chatLink: 'https://ig.me/AU_sim_racing',
    status: {
      name: 'Permissions issue',
      color: 'red',
      outline: true,
    },
    dateConnected: '2024-04-01T15:32:27.118Z',
  },
]

export const instagramUsersList: AccountListType[] = [
  {
    icon: require('@/assets/images/chats/instagram.svg'),
    title: 'Instagram',
    description: [
      'Click "Login with Instagram" to sign in to your Instagram ',
      {
        text: 'Business',
        link: '/',
      },
      ' or ',
      {
        text: 'Creator',
        link: '/',
      },
      ' account and complete the guided setup for integration.',
    ],
  },
]
