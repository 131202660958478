
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { StatusType } from '@/definitions/shared/types'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    title: {
      type: String,
    },
    chatLink: {
      type: String,
    },
    status: {
      type: Object as PropType<StatusType>,
    },
  },
  setup() {
    const { openInstagramQrCodeModal, openInstagramUnlinkModal } = useChatsSettings()
    return {
      openInstagramQrCodeModal,
      openInstagramUnlinkModal,
    }
  },
})
