
import { defineComponent, ref, computed } from 'vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import ChatInstagramEmpty from '@/components/pages/chats/settings/instagram/ChatInstagramEmpty.vue'
import ChatInstagramFilter from '@/components/pages/chats/settings/instagram/ChatInstagramFilter.vue'
import ChatInstagramTable from '@/components/pages/chats/settings/instagram/ChatInstagramTable.vue'
import ChatInstagramFaq from '@/components/pages/chats/settings/instagram/ChatInstagramFaq.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { InstagramLineType } from '@/definitions/chats/settings/instagram/types'
import { instagramMock } from '@/definitions/chats/settings/instagram/data'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    WhiteBlock,
    DetailsHero,
    ChatsSettingsPage,
    ChatInstagramEmpty,
    ChatInstagramFilter,
    ChatInstagramTable,
    ChatInstagramFaq,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Instagram' },
    ]

    const search = ref('')
    const selected = ref<InstagramLineType[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Instagram account', value: 'account-slot' },
      { text: 'Username', value: 'username-slot' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Date connected', value: 'dateConnected', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref<InstagramLineType[]>(instagramMock)
    const filteredTableItems = computed<InstagramLineType[]>(() => {
      return tableItems.value.filter((item) => item.account.title.toLowerCase().includes(search.value.toLowerCase()) || item.username.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      breadcrumbs,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
