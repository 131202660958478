
import { defineComponent } from 'vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'

export default defineComponent({
  components: {
    FaqBlock,
    TmExpansion,
  },
  setup() {
    const titles = [
      'What is Instagram integration?',
      'How do I set up Instagram integration?',
      'Can I connect multiple Instagram accounts?',
      'What is a 24-hour session?',
      'I have multiple projects. Can I manage them all?',
      'Basic concepts: what is a task, a board and a stage?',
    ]

    return {
      titles,
    }
  },
})
