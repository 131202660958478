
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import Highlight from '@/components/shared/Highlight.vue'
import ChatInstagramDropdown from '@/components/pages/chats/settings/instagram/ChatInstagramDropdown.vue'

export default defineComponent({
  components: {
    TmTable,
    TmAvatar,
    TmStatus,
    TmButton,
    Highlight,
    ChatInstagramDropdown,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
})
